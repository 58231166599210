<template>
  <div>
    <div class="pricing-step pricing-step_second">
      <div
        v-if="ifLoader"
        class="if-loader"
      ></div>
      <div class="container">
        <h3 class="pricing-step__title">
          Contact & Billing Information
        </h3>
        <form
          id="form-payment"
          class="impact-form"
          autocomplete="off"
          @submit.prevent="sendPaymentData($event)"
        >
          <div class="impact-form-block">
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                First Name
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.firstName"
                  type="text"
                  placeholder="First name"
                  autofocus
                  data-recurly="first_name"
                  name="firstName"
                  :class="{'error': validator.errors.has('firstName')}"
                  @keyup="formatField($event)"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('firstName')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('firstName')}"
                  >
                    {{ validator.errors.first('firstName') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Last Name
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.lastName"
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  data-recurly="last_name"
                  :class="{'error': validator.errors.has('lastName')}"
                  @keyup="formatField($event)"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('lastName')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('lastName')}"
                  >
                    {{ validator.errors.first('lastName') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Email
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  :class="{'error': validator.errors.has('email')}"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('email')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('email')}"
                  >
                    {{ validator.errors.first('email') }}
                  </span>
                </transition>
              </div>
            </div>
          </div>
          <div class="impact-form-block">
            <div class="card">
              <div
                v-for="(item) in imageCard"
                :key="item.id"
                class="card__img"
              >
                <img
                  :src="require('./assets/images/' + item.img + '.png')"
                  alt=""
                >
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">Card Number</span>
              <div class="impact-form-item__input">
                <div
                  data-recurly="number"
                  :class="{'error': validator.errors.has('number')}"
                ></div>
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('number')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('number')}"
                  >
                    {{ validator.errors.first('number') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-list">
              <div class="impact-form-item">
                <span class="impact-form-item__text">Expiration month</span>
                <div class="impact-form-item__input">
                  <div
                    data-recurly="month"
                    :class="{'error': validator.errors.has('month')}"
                  ></div>
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('month')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('month')}"
                    >
                      {{ validator.errors.first('month') }}
                    </span>
                  </transition>
                </div>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">Expiration year</span>
                <div class="impact-form-item__input">
                  <div
                    data-recurly="year"
                    :class="{'error': validator.errors.has('year')}"
                  ></div>
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('year')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('year')}"
                    >
                      {{ validator.errors.first('year') }}
                    </span>
                  </transition>
                </div>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  CVV
                  <i class="icon-info"></i>
                </span>
                <div class="impact-form-item__input">
                  <div
                    data-recurly="cvv"
                    :class="{'error': validator.errors.has('cvv')}"
                  ></div>
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('cvv')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('cvv')}"
                    >
                      {{ validator.errors.first('cvv') }}
                    </span>
                  </transition>
                </div>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Country
              </span>
              <div class="impact-form-item__input">
                <SelectWithKeyTemplate
                  :settings="selectCountrySettings"
                  @changeSelect="changeCountry($event)"
                />
                <span
                  v-show="validator.errors.has('setCountry')"
                  class="help"
                  :class="{'is-danger': validator.errors.first('setCountry')}"
                >
                  {{ validator.errors.first('setCountry') }}
                </span>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Street
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.address"
                  data-recurly="address1"
                  type="text"
                  placeholder="Address"
                  name="address"
                  :class="{'error': validator.errors.has('address')}"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('address')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('address')}"
                  >
                    {{ validator.errors.first('address') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text impact-form-item__text_address"></span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.address2"
                  type="text"
                  placeholder="Address"
                  name="address"
                >
                <!--                :class="{'error': validator.errors.has('address2')}"-->

                <!--                <transition name="fade-msg">-->
                <!--                  <span v-show="validator.errors.has('address2')"-->
                <!--                        class="help"-->
                <!--                        :class="{'is-danger': validator.errors.first('address2')}"-->
                <!--                  >-->
                <!--                    {{ validator.errors.first('address2') }}-->
                <!--                  </span>-->
                <!--                </transition>-->
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                City
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.city"
                  data-recurly="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  :class="{'error': validator.errors.has('city')}"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('city')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('city')}"
                  >
                    {{ validator.errors.first('city') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span
                    v-if="countryActive === 'US'"
                    class="required"
                  >
                    *
                  </span>
                  State
                </span>
                <template v-if="countryActive === 'US'">
                  <div class="impact-form-item__input">
                    <SelectWithKeyTemplate
                      :settings="selectStateSettings"
                      @changeSelect="changeState($event)"
                    />
                    <span
                      v-show="validator.errors.has('setState')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('setState')}"
                    >
                      {{ validator.errors.first('setState') }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="impact-form-item__input impact-form-item__input_mini">
                    <input
                      v-model="setState"
                      type="text"
                      data-recurly="state"
                      placeholder="State"
                      name="state"
                      :class="{'error': validator.errors.has('setState')}"
                      @keyup="formatField($event)"
                    >
                    <transition name="fade-msg">
                      <span
                        v-show="validator.errors.has('setState')"
                        class="help"
                        :class="{'is-danger': validator.errors.first('setState')}"
                      >
                        {{ validator.errors.first('setState') }}
                      </span>
                    </transition>
                  </div>
                </template>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span class="required">*</span>
                  ZIP/Postal
                </span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.zip"
                    data-recurly="postal_code"
                    type="text"
                    placeholder="Zip"
                    name="postal_code"
                    :class="{'error': validator.errors.has('postal_code')}"
                    class="postcode"
                  >
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('postal_code')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('postal_code')}"
                    >
                      {{ validator.errors.first('postal_code') }}
                    </span>
                  </transition>
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="recurly-token"
            data-recurly="token"
          >
        </form>
        <div class="order-summary">
          <div class="order-summary__title">
            Order Summary
          </div>
          <div class="order-summary-list">
            <div class="order-summary-item">
              <div class="order-summary-item__title">
                Setup Fee
              </div>
              <div class="order-summary-item__price">
                {{ setupFee }}
              </div>
            </div>
            <div class="order-summary-item">
              <div class="order-summary-item__title">
                Your
                <template v-if="pricingObj.period === 'monthly'">
                  Monthly
                </template>
                <template v-else>
                  {{ pricingObj.period === '2yearly' ? '2-Year' : 'Annual' }}
                </template>
                Plan
                <template v-if="pricingObj.period === '2yearly'">
                  (20% discount)
                </template>
                <template v-if="pricingObj.period === 'yearly'">
                  (10% discount)
                </template>
              </div>
              <div class="order-summary-item__price">
                {{ monthlyFeeText }}
              </div>
            </div>
            <div class="order-summary-item order-summary-item_total">
              <div class="order-summary-item__title">
                Total
              </div>
              <div class="order-summary-item__price">
                $ {{ setupFee + monthlyFee }}
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-step-btn-row">
          <!--          <button-->
          <!--            class="btn pricing-step__btn"-->
          <!--            @click.prevent="sendPaymentData($event)"-->
          <!--          >-->
          <!--            Subscribe-->
          <!--          </button>-->
          <input
            type="submit"
            class="btn pricing-step__btn"
            value="Subscribe"
            @click.prevent="sendPaymentData($event)"
          >
          <transition name="fade-msg">
            <span
              v-show="validator.errors.has('subscribe')"
              class="help"
              :class="{'is-danger': validator.errors.first('subscribe')}"
            >
              {{ validator.errors.first('subscribe') }}
            </span>
          </transition>
          <!--          <radial-progress-bar :diameter="210"-->
          <!--                               :completed-steps="completedSteps"-->
          <!--                               :startColor="'#716FCE'"-->
          <!--                               :stopColor="'#716FCE'"-->
          <!--                               :strokeWidth="15"-->
          <!--                               :innerStrokeColor="'#F5F6F7'"-->
          <!--                               :total-steps="totalSteps">-->
          <!--            <p>{{ completedSteps }}%</p>-->
          <!--          </radial-progress-bar>-->
        </div>
      </div>
    </div>
    <transition
      name="fade-modal"
      appear
    >
      <!--      v-if="showModalInfo"-->
      <!--      <modals-info-->
      <!--        v-if="showModalInfo && !isMobile"-->
      <!--      />-->
      <!--      <modals-info-mobile-->
      <!--        v-if="!showModalInfo"-->
      <!--      />-->
    </transition>
    <transition
      name="fade-modal"
      appear
    >
      <modals-info-error
        v-if="showModalInfoError"
      >
      </modals-info-error>
    </transition>
    <transition
      name="fade-modal"
      appear
    >
      <modals-verify
        v-if="showModalVerify"
        :isAfrica="isAfrica"
      >
      </modals-verify>
    </transition>
  </div>
</template>

<script>
// import checkbox from '@/components/checkbox'
import SelectWithKeyTemplate from '@/components/selectWithKeyTemplate';
// import modalsInfo from '@/components/modals/modalsInfo'
// import modalsInfoMobile from '@/components/modals/modalsInfoMobile'
import modalsInfoError from '@/components/modals/modalsInfoError';
import modalsVerify from '@/components/modals/modalsVerify';
import { API_KEY } from '@/constants/apiKey';
import { Validator, ErrorBag } from 'vee-validate';
import pricingApi from '@/api/pricingApi';
import { COUNTRY_LIST, AFRICA_LIST } from '@/constants/country';
import { STATE_LIST } from '@/constants/state';
// import RadialProgressBar from 'vue-radial-progress'
// import MobileDetect from 'mobile-detect'

export default {
  name: 'Index',
  components: {
    // checkbox,
    SelectWithKeyTemplate,
    // modalsInfo,
    modalsVerify,
    modalsInfoError,
    // RadialProgressBar,
    // modalsInfoMobile
  },
  props: {
    isPrayerwall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resApi: '',
      // timeProgress: 500,
      firstCount: '',
      timeProgress: 150,
      totalSteps: 100,
      disabledState: false,
      ifLoader: false,
      recurlyToken: '',
      returnUrl: '',
      countryList: COUNTRY_LIST,
      stateList: STATE_LIST,
      validator: {},
      imageCard: [
        {
          id: 0,
          img: 'card__img-1',
        },
        {
          id: 1,
          img: 'card__img-2',
        },
        {
          id: 2,
          img: 'card__img-3',
        },
        {
          id: 3,
          img: 'card__img-4',
        },
        {
          id: 4,
          img: 'card__img-5',
        },
        {
          id: 5,
          img: 'card__img-6',
        },
      ],
      monthActive: 1,
      yearActive: 1,
      recurly: '',
      debug: false,
    };
  },
  computed: {
    isAfrica() {
      return AFRICA_LIST.includes(this.setCountry);
    },
    showIframeEmail: {
      get() {
        return this.$store.getters.showIframeEmail;
      },
      set(data) {
        this.$store.commit('setShowIframeEmail', data);
      },
    },
    userId: {
      get() {
        return this.$store.getters.userId;
      },
      set(data) {
        this.$store.commit('setUserId', data);
      },
    },
    tags: {
      get() {
        return this.$store.getters.tags;
      },
      set(data) {
        this.$store.commit('changeTags', data);
      },
    },
    adminLink: {
      get() {
        return this.$store.getters.adminLink;
      },
      set(data) {
        this.$store.commit('changeAdminLink', data);
      },
    },
    flag: {
      get() {
        return this.$store.getters.flag;
      },
      set(data) {
        this.$store.commit('changeFlag', data);
      },
    },
    completedSteps: {
      get() {
        return this.$store.getters.completedSteps;
      },
      set(data) {
        this.$store.commit('changeCompletedSteps', data);
      },
    },
    createAdminReady: {
      get() {
        return this.$store.getters.createAdminReady;
      },
      set(data) {
        this.$store.commit('setCreateAdminReady', data);
      },
    },
    createAdminReadyTest: {
      get() {
        return this.$store.getters.createAdminReadyTest;
      },
      set(data) {
        this.$store.commit('setCreateAdminReadyTest', data);
      },
    },
    respAdmin: {
      get() {
        return this.$store.getters.respAdmin;
      },
      set(data) {
        this.$store.commit('setRespAdmin', data);
      },
    },
    ifLoaderInfo: {
      get() {
        return this.$store.getters.ifLoaderInfo;
      },
      set(data) {
        this.$store.commit('setIfLoaderInfo', data);
      },
    },
    showModalVerify: {
      get() {
        return this.$store.getters.showModalVerify;
      },
      set(data) {
        this.$store.commit('setShowModalVerify', data);
      },
    },
    stepFirstId: {
      get() {
        return this.$store.getters.stepFirstId;
      },
      set(data) {
        this.$store.commit('setStepFirstId', data);
      },
    },
    countryActive: {
      get() {
        return this.$store.getters.countryActive;
      },
      set(data) {
        this.$store.commit('setCountryActive', data);
      },
    },
    stateActive: {
      get() {
        return this.$store.getters.stateActive;
      },
      set(data) {
        this.$store.commit('setStateActive', data);
      },
    },
    validPromoCode: {
      get() {
        return this.$store.getters.validPromoCode;
      },
      set(data) {
        this.$store.commit('setValidPromoCode', data);
      },
    },
    errorPromo: {
      get() {
        return this.$store.getters.errorPromo;
      },
      set(data) {
        this.$store.commit('setErrorPromo', data);
      },
    },
    setupFee() {
      const { promo = 0, services = {}, setup } = this.pricingObj;
      const { additionals = [] } = services;
      const customDesign = additionals.filter((item) => item.includes('custom_design'));
      const customDesignPrice = customDesign.length ? 250 : 0;
      const setupFee = Object.keys(services).filter((item) => item === 'mobile' || item === 'tv');
      const setupFeePrice = setupFee.length ? 250 : 0;
      const fullSetupPrice = promo && this.validPromoCode
        ? customDesignPrice + setupFeePrice
        : setup;
      return fullSetupPrice;
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    showModalInfo: {
      get() {
        return this.$store.getters.showModalInfo;
      },
      set(data) {
        this.$store.commit('setShowModalInfo', data);
      },
    },
    showModalInfoError: {
      get() {
        return this.$store.getters.showModalInfoError;
      },
      set(data) {
        this.$store.commit('setShowModalInfoError', data);
      },
    },
    countryListing() {
      const newArr = [];
      for (let i = 0; i < this.countryList.length; i++) {
        newArr.push({
          key: this.countryList[i].code,
          text: this.countryList[i].name,
        });
      }
      return newArr;
    },
    stateListing() {
      const newArr = [];
      for (const key in this.stateList) {
        newArr.push({
          key,
          text: this.stateList[key],
        });
      }
      return newArr;
    },
    selectCountrySettings() {
      return {
        optionList: this.countryListing,
        fontFamily: false,
        placeholderText: '',
        selectedKey: this.setCountry,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select-impact',
        dataRecurly: 'country',
      };
    },
    selectStateSettings() {
      return {
        optionList: this.stateListing,
        fontFamily: false,
        placeholderText: '',
        selectedKey: this.setState,
        disable: this.disabledState,
        toTop: false,
        tabindex: 1,
        selectClass: 'select-impact select_137',
        dataRecurly: 'state',
      };
    },
    setState: {
      get() {
        return this.stateActive;
      },
      set(data) {
        this.stateActive = data;
      },
    },
    setCountry: {
      get() {
        return this.countryActive;
        // return this.$store.getters.timeZoneList
      },
      set(data) {
        this.countryActive = data;
        // Current Time Zone
        pricingApi.timezonesList(this.countryActive).then((resp) => {
          console.log(resp);
          this.$store.commit('setTimezoneList', resp.data.tz);
        }).catch((err) => {
          console.log(err);
        });
      },
    },
    setYear: {
      get() {
        return this.yearActive;
      },
      set(data) {
        this.yearActive = data;
      },
    },
    monthlyFee() {
      const { promo = 0, amount, period } = this.pricingObj;
      const isYear = period === 'yearly' || period === '2yearly';
      const countMonths = period === 'yearly' ? 12 : 24;
      const discountYear = period === 'yearly' ? 0.9 : 0.8;
      const totalDiscount = isYear ? discountYear : 1;
      const totalCountMonths = isYear ? countMonths : 1;
      const totalAmount = amount * totalCountMonths * totalDiscount;

      const promoPrayerDiscount = 0;
      const validPromoCode = this.isPrayerwall
        ? promo === 'trial12' || 'ppunlim'
        : this.validPromoCode;
      const promoDiscount = this.isPrayerwall ? promoPrayerDiscount : 0.75;

      return promo && validPromoCode
        ? totalAmount * promoDiscount
        : totalAmount;
    },
    monthlyFeeText() {
      return this.convertNumber(this.monthlyFee.toFixed());
    },
  },
  mounted() {
    window.recurly.configure({
      publicKey: API_KEY,
      fields: {
        // affects all fields
        all: {
          style: {
            // fontFamily: 'SF-Pro-Display-Regular',
            fontSize: '18px',
            fontColor: '#303133',
          },
        },
        month: {
          inputType: 'mobileSelect',
          style: {
            placeholder: {
              content: 'MM',
            },
          },
        },
        year: {
          style: {
            placeholder: {
              content: 'YYYY',
            },
          },
        },
        cvv: {
          style: {
            placeholder: {
              content: 'CVV',
            },
          },
        },
      },
    });
  },
  beforeMount() {
    if (this.pricingObj.firstName === ''
      || this.pricingObj.lastName === ''
      || this.pricingObj.email === ''
      || this.pricingObj.organization === ''
    ) {
      this.$router.push({ name: 'index' });
    }
    // const md = new MobileDetect(window.navigator.userAgent)
    // const mobile = md.mobile()
    // const tablet = md.tablet()
    // if (mobile !== null || tablet !== null) {
    //   this.isMobile = true
    // }
    console.log(this.pricingObj);
    if (this.setCountry === 'US') {
      this.disabledState = false;
    } else {
      this.disabledState = true;
      // this.countryActive = ''
    }
    this.validator = Validator.create();
    // this.$refs.captcha.reset()
    const dict = {
      en: {
        custom: {
          firstName: {
            required: 'The First Name is required.',
          },
          lastName: {
            required: 'The Last Name is required.',
          },
          setState: {
            required: 'The State is required.',
          },
          email: {
            required: 'The Email is required.',
            email: 'The Email field must be a valid email.',
          },
          // number: {
          //   required: 'The Number is required.'
          // },
          // month: {
          //   required: 'The Month is required.'
          // },
          // year: {
          //   required: 'The Year is required.'
          // },
          // cvv: {
          //   required: 'The CVV is required.'
          // },
          city: {
            required: 'The City is required.',
          },
          postal_code: {
            required: 'The Zip is required.',
          },
          setCountry: {
            required: 'The Country is required.',
          },
          address: {
            required: 'The Address is required.',
          },
          // address2: {
          //   required: 'The Address 2 is required.'
          // }
        },
      },
    };
    this.validator.localize(dict);
    this.validator.attach({ name: 'firstName', rules: { required: true } });
    this.validator.attach({ name: 'setState', rules: { required: true } });
    this.validator.attach({ name: 'lastName', rules: { required: true } });
    this.validator.attach({ name: 'email', rules: { required: true, email: true } });
    // this.validator.attach({name: 'number', rules: {'required': true}})
    // this.validator.attach({name: 'month', rules: {'required': true}})
    // this.validator.attach({name: 'year', rules: {'required': true}})
    // this.validator.attach({name: 'cvv', rules: {'required': true}})
    this.validator.attach({ name: 'city', rules: { required: true } });
    this.validator.attach({ name: 'postal_code', rules: { required: true } });
    this.validator.attach({ name: 'setCountry', rules: { required: true } });
    this.validator.attach({ name: 'address', rules: { required: true } });
    // this.validator.attach({name: 'address2', rules: {'required': true}})

    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  beforeDestroy() {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
  },
  methods: {
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      const thisEl = this;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        thisEl.scrollTo(element, to, duration - 10);
      }, 10);
    },
    formatField(ev) {
      const el = ev.target;
      const val = el.value.replace(/\s{2,}/g, ' ').split(' ');
      const res = [];
      for (const idx in val) {
        if (val[idx] !== ' ') {
          res.push(val[idx].substr(0, 1).toUpperCase() + val[idx].substr(1).toLowerCase());
        }
      }
      el.value = res.join(' ');
    },
    sendPaymentData() {
      this.validator.validateAll({
        firstName: this.pricingObj.firstName,
        lastName: this.pricingObj.lastName,
        email: this.pricingObj.email,
        city: this.pricingObj.city,
        address: this.pricingObj.address,
        postal_code: this.pricingObj.zip,
        setCountry: this.setCountry,
        setState: this.setCountry === 'US' ? this.setState : true,
      }).then((result) => {
        if (result) {
          console.log(result, this.debug);
          window.fbq('trackCustom', 'Sign Up Form Step 5 - Billing');
          const form = document.getElementById('form-payment');
          this.ifLoader = true;
          console.log('window.recurly', window.recurly);
          window.recurly.token(form, (err, token) => {
            this.ifLoader = false;
            console.log('err', err);
            if (err) {
              console.log(err);
              const bagError = new ErrorBag();
              for (let i = 0; i < err.details.length; i++) {
                bagError.add({
                  field: err.details[i].field,
                  msg: err.details[i].messages[0],
                });
              }
              console.log(bagError);
              this.validator.errors = bagError;
              this.$nextTick(() => {
                const myElement = document.getElementsByClassName('is-danger');
                console.log(myElement[0]);
                if (myElement[0] !== undefined) {
                  const rec = myElement[0].getBoundingClientRect();
                  const res = rec.top + window.scrollY;
                  this.scrollTo(window, res - 60, 300, 'clear');
                }
              });
            } else {
              console.log(true);
              // form.submit()
              // console.log(token.id)
              this.recurlyToken = token ? token.id : 'fff';
              this.returnUrl = 'testurl';
              this.ifLoader = true;
              pricingApi.sendPay({
                id: this.stepFirstId,
                data: {
                  recurlyToken: this.recurlyToken,
                  returnUrl: this.returnUrl,
                  test: this.debug ? 1 : undefined,
                },
              }).then((response) => {
                console.log(response);
                if (response.data.error === undefined) {
                  const total = this.setupFee + this.monthlyFee;
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-699985331/QwUGCMnxibEBELPb480C', value: total, currency: 'USD', transaction_id: '',
                  });
                  pricingApi.updateMember({ tags: this.tags }, this.userId).then((resp) => {
                    console.log(resp);
                  }).catch((err) => {
                    console.log(err);
                  });
                  if (this.pricingObj.services.give !== undefined && this.pricingObj.services.give !== null) {
                    console.log('give!!!');
                    // this.showModalInfo = false
                    // this.setCountry
                    console.log('isAfrica', this.isAfrica);
                    if (this.isAfrica) {
                      this.showModalVerify = true;
                    } else {
                      pricingApi.sendPayEpic({ id: this.stepFirstId }).then((response) => {
                        this.ifLoader = false;
                        if (response.data.applicationLink) {
                          this.showModalVerify = true;
                          this.showIframeEmail = response.data.applicationLink;
                          // window.open(response.data.applicationLink, '_self')
                        }
                      }).catch((err) => {
                        console.log(err);
                        this.ifLoader = false;
                      });
                    }
                  } else {
                    this.ifLoader = false;
                    // this.showModalInfo = true
                    window.fbq('trackCustom', 'Sign Up Form Step 6 - Admin Ready');
                    this.$router.push({ name: 'registrationconfirmation' });
                    /*
                    if (!this.resApi) {
                      this.respAdmin = false
                      let timerId = setInterval(() => {
                        if (!this.respAdmin) {
                          if (this.completedSteps <= 98) {
                            this.completedSteps++
                          }
                        } else {
                          this.completedSteps = 98
                        }
                        console.log(timerId)
                        if (this.respAdmin && this.completedSteps === 100) {
                          clearInterval(timerId)
                          console.log('STOP')
                        }
                      }, 1500)
                    }
                    let timerCreateAdmin = setInterval(() => {
                      pricingApi.createAdmin(this.pricingObj.domain).then((response) => {
                      // pricingApi.createAdmin('test15').then((response) => {
                        console.log('createAdmin')
                        if (!response.data.result) {
                          this.resApi = response.data.result
                          // this.respAdmin = false
                          // let timerId = setInterval(() => {
                          //   if (!this.respAdmin) {
                          //     if (this.completedSteps <= 95) {
                          //       this.completedSteps++
                          //     }
                          //   } else {
                          //     this.completedSteps = 95
                          //   }
                          //   console.log(timerId)
                          //   if (this.respAdmin && this.completedSteps === 99) {
                          //     clearInterval(timerId)
                          //     console.log('STOP')
                          //   }
                          // }, 1500)
                        } else {
                          clearInterval(timerCreateAdmin)
                          this.adminLink = response.data.link
                          this.completedSteps = 100
                          this.respAdmin = true
                          window.fbq('trackCustom', 'Sign Up Form Step 6 - Admin Ready')
                        }
                      }).catch((err) => {
                        console.log('ErrorCreateAdmin')
                        console.log(err)
                        this.ifLoaderInfo = true
                      })
                    }, 10000)
                    */
                    // this.ifLoader = false
                    // this.showModalInfo = true
                  }
                } else {
                  this.ifLoader = false;
                  const bagError = new ErrorBag();
                  bagError.add({
                    field: 'subscribe',
                    msg: response.data.error,
                  });
                  console.log(bagError);
                  this.validator.errors = bagError;
                }
                console.log(response);
              }).catch((err) => {
                console.log(err);
                this.showModalInfoError = true;
                this.ifLoader = false;
              });
            }
            // console.log(token)
          });
        } else {
          this.$nextTick(() => {
            const myElement = document.getElementsByClassName('is-danger');
            if (myElement[0] !== undefined) {
              const rec = myElement[0].getBoundingClientRect();
              const res = rec.top + window.scrollY;
              this.scrollTo(window, res - 60, 300, 'clear');
            }
          });
        }
      });
    },
    convertNumber(number) {
      return String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    changeCountry(key) {
      this.setCountry = key;
      if (key === 'US') {
        this.disabledState = false;
      } else {
        this.disabledState = true;
      }
    },
    changeState(key) {
      this.setState = key;
    },
  },
};
</script>

<style lang="scss">
  @import '../src/assets/sass/utils/variables';

  .pricing-step {

    &__title {
      font-size: 26px;
      line-height: 40px;
      font-family: $font-global-bold, sans-serif;
      color: $color-shark;

      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 32px;
        text-align: center;
      }

    }
  }

  .order-summary {
    padding-top: 42px;

    &__title {
      font-size: 26px;
      line-height: 35px;
      color: $color-shark;
      font-family: $font-global-bold, sans-serif;
      margin-bottom: 37px;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
      }

    }

    &-list {
      max-width: 962px;
      position: relative;
      width: 100%;
      justify-content: center;
    }

    &-item {
      background: $color-white;
      //border: 1px solid $color-picton-blue ;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: 16px;
      margin-bottom: 7px;

      &_total {
        border: none;
        border-top: 1px solid $color-alto;
        margin-top: 26px;
        padding-top: 23px;

        .order-summary-item__title, .order-summary-item__price {
          font-size: 18px;
          line-height: 30px;
          color: $color-jumbo;

          @media (max-width: 767px) {
            font-size: 13px;
            line-height: 21px;
          }

        }
      }

      &__title, &__price {
        font-size: 21px;
        line-height: 32px;
        font-family: $font-global-bold, sans-serif;
        color: $color-shark;

        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 22px;
        }

      }
    }
  }

  .card {
    display: flex;
    justify-content: center;
    margin-top: -9px;
    margin-right: 82px;
    margin-bottom: 26px;

    @media (max-width: 955px) {
      margin: 7px auto 5px;
    }

    &__img {
      margin: 0 4px;
    }
  }

  .recurly-hosted-field{
    max-width: 382px;
    width: 100%;
    border: 1px solid $color-alto;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: $font-global;
    font-size: 18px;
    color: $color-shark;
    height: 40px;
    transition: border-color .15s ease-in;

    @media (max-width: 767px) {
      font-size: 14px;
      max-width: 100%;
    }

    &:hover {
      border-color: $color-french-gray;
    }

    &-focus {
      border-color: $color-royal-blue-third!important;
    }
    /*recurly-hosted-field-number
    recurly-hosted-field-month
    recurly-hosted-field-year
    recurly-hosted-field-cvv*/
  }
</style>

<style lang="scss" scoped>
  @import '../src/assets/sass/utils/variables';

  .container {
    max-width: 1005px;
    width: 100%;
  }

  .pricing-step {
    margin-top: 17px;
    margin-bottom: 80px;

    @media (max-width: 767px) {
      padding-top: 60px;
    }

    &-btn-row {
      position: relative;
      margin-top: 15px;

      .is-danger {
        left: 50%;
        transform: translateX(-50%);
        top: -34px;
      }

    }

    &__btn {
      //left: -10px;
      margin: auto;
      background: $color-moody-blue;
      height: 48px;
      border: none;
      font-family: $font-global-medium;
      font-size: 21px;
      color: $color-white;
      padding: 0 6px;
      width: 100%;
      max-width: 305px;
      border-radius: 5px;
      text-decoration: none;
      transition: opacity .2s ease-in, background .2s ease-in;
      user-select: none;
      -webkit-appearance: none;

      &_disabled {
        background: $color-gray;
        pointer-events: none;
      }

      &:hover {
        opacity: .8;
      }

      &:active {
        background: $color-martinique;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:focus:not(:active)::after {
        animation: ripple 1.5s ease-out;
      }
    }
  }

  .impact-form {
    user-select: none;
    max-width: 959px;
    width: 100%;
    margin: auto;

    @media (max-width: 767px) {
      max-width: 550px;
    }

    &-list {
      display: flex;
      padding-left: 216px;
      margin-bottom: 32px;

      @media (max-width: 767px) {
        padding-left: 0;
        margin-bottom: 10px;
      }

      .impact-form-item {
        flex-direction: column;
        width: 117px;
        align-items: flex-start;

        @media (max-width: 767px) {
          width: 100%;
        }

        input {
          width: 117px;
        }

        & + .impact-form-item {
          margin-left: 16px;
        }
      }

      .impact-form-item__text {
        font-size: 16px;
        line-height: 24px;
        color: $color-jumbo;
        width: auto;
        margin-right: 0;
        margin-bottom: 8px;

        @media (max-width: 767px) {
          font-size: 13px;
          line-height: 18px;
        }

        @media (max-width: 350px) {
          font-size: 11px;
          line-height: 16px;
        }

      }
    }

    &-block {
      border-bottom: 1px solid $color-alto;
      padding: 40px 15px;
      padding-bottom: 23px;
      padding-left: 47px;

      @media (max-width: 767px) {
        padding-left: 15px;
      }

      @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 7px;
      }

      &_size {
        padding-right: 0;
      }

      &__box {
        display: flex;

        @media (max-width: 767px) {
          .impact-form-item {
            flex-grow: 1;
          }
        }

        .impact-form-item + .impact-form-item {
          margin-left: 29px;

          @media (max-width: 767px) {
            margin-left: 15px;
          }

          .impact-form-item__text {
            width: 100px;
          }

          .impact-form-item__input {
            input {
              width: 100px;

              @media (max-width: 767px) {
                width: 100%;
              }

            }
          }
        }
      }

      &__desc {
        font-size: 14px;
        line-height: 18px;
        font-family: $font-global, sans-serif;
        color: $color-jumbo;
        text-align: left;
        margin-bottom: 20px;
        margin-left: 216px;

        span {
          display: block;
        }
      }
    }

    &-item {
      position: relative;
      height: 40px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: block;
        height: auto;
        width: 100%;
        margin-bottom: 5px;
      }

      .icon-info {
        font-size: 12px;
        color: $color-moody-blue;
        position: relative;
        left: 2px;
        top: -1px;
        cursor: pointer;
      }

      &_address {
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }

      &_281 {
        input {
          width: 281px !important;
        }
      }

      &__url {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global, sans-serif;
        color: $color-chateau-green;
        margin-left: 8px;
      }

      &__text {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global, sans-serif;
        margin-right: 16px;
        position: relative;
        top: -1px;
        width: 200px;
        text-align: right;

        @media (max-width: 767px) {
          width: 100%;
          font-size: 14px;
        }

        &_address {
          @media (max-width: 767px) {
            display: block;
            margin-bottom: 15px;
          }
        }

      }

      &__input {
        position: relative;

        &_mini {
          input {
            width: 137px !important;
          }
        }

        input {
          width: 382px;
          border: 1px solid $color-alto;
          border-radius: 4px;
          padding-left: 10px;
          padding-right: 10px;
          font-family: $font-global;
          font-size: 18px;
          color: $color-shark;
          height: 40px;
          transition: border-color .15s ease-in;

          @media (max-width: 767px) {
            width: 100%!important;
            font-size: 14px;
          }

          &:hover {
            border-color: $color-french-gray;
          }

          &:focus {
            border-color: $color-royal-blue-third;
          }
          &:focus::placeholder {
            opacity: 0;
          }

          &::-webkit-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-ms-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::placeholder {
            color: $color-alto;
            font-family: $font-global;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '../src/assets/sass/utils/variables';

  .required {
    color: $color-punch;
  }

  .error {
    border-color: $color-punch !important;
    background-color: $color-white !important;
  }
  .help {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 12px;
    color: $color-punch;
    box-shadow: none;
    padding: 0;
    background: transparent;
    transform: translateY(0);

    @media (max-width: 767px) {
      top: 100%
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .radial-progress-container {
    p {
      font-size: 40px;
      text-align: center;
      color: $color-moody-blue;
      font-family: $font-global, sans-serif;
      font-weight: 100;
    }
  }
</style>
