<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-info"
      @contextmenu.self.prevent
      @click="showModalVerify = false"
      @shortkey="showModalVerify = false"
    >
      <div
        class="modals-info__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-info__close"
          @click="showModalVerify = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <VuePerfectScrollbar class="modals-info__scroll">
          <div
            class="modals-info__iframe"
          >
            <div
              v-if="isAfrica"
              class="modals-info__text"
            >
              <div>
                To use our Giving Platform you will need to get account with Flutterwave,
                a payment processing company in Africa. Please click on the link below to proceed to registartion to get
                your merchant account setup.
              </div>
              <div>
                <a
                  href="https://dashboard.flutterwave.com/signup?referrals=RV676507"
                  target="_blank"
                >
                  https://dashboard.flutterwave.com/signup?referrals=RV676507
                </a>
              </div>
              <div>
                After your account is setup with Flutterwave, please contact us via
                <a href="mailto:support@impactfactors.net">support@impactfactors.net</a> and we will
                activate your account within our platform!
              </div>
            </div>
            <iframe
              v-else
              id="ifr"
              :src="showIframeEmail"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ModalsWaitGiving',
  components: {
    VuePerfectScrollbar,
  },
  props: ['giveModuleVar', 'isAfrica'],
  data() {
    return {
    };
  },
  computed: {
    showModalVerify: {
      get() {
        return this.$store.getters.showModalVerify;
      },
      set(data) {
        this.$store.commit('setShowModalVerify', data);
      },
    },
    showIframeEmail: {
      get() {
        return this.$store.getters.showIframeEmail;
      },
      set(data) {
        this.$store.commit('setShowIframeEmail', data);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';

  .modals-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);
    padding: 15px;

    &__scroll {
      max-height: 100%;
    }

    &__content {
      max-width: 1100px;
      width: 100%;
      max-height: 90%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 65px 15px;
      padding-bottom: 15px;

      @media (max-width: 767px) {
        padding: 20px 15px;
      }
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 40px;
      font-size: 31px;
      color: $color-crimson;
      text-align: center;
      margin-bottom: 16px;

      @media (max-width: 1139px) {
        font-size: 25px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
        margin-bottom: 10px;
      }

    }
    &__iframe {
      display: flex;
      justify-content: center;
      align-items: center;

      #ifr {
        max-width: 1070px;
        width: 100%;
        margin: auto;
        height: 700px;

        @media (max-width: 767px) {
          height: 400px;
        }

      }
    }

    &__text {
      padding: 35px 25px 85px;
      font-size: 26px;
      line-height: 40px;
      font-family: $font-global;
      /*margin-bottom: 50px;*/
      color: $color-shark;
      /*max-width: 505px;*/
      width: 100%;

      @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 20px;
      }

      div + div {
        margin-top: 15px;
      }

      a {
        color: $color-moody-blue;
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }

      }

    }

  }
</style>
