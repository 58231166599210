<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-info-error"
      @contextmenu.self.prevent
      @click="showModalInfoError = false"
      @shortkey="showModalInfoError = false"
    >
      <div
        class="modals-info-error__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-info-error__close"
          @click="showModalInfoError = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-info-error__title">
          Data Error
        </div>
        <div class="modals-info-error__desc">
          <div>
            <!--            Data Error-->
          </div>
        </div>
        <button
          class="btn modals-info-error__btn"
          @click="showModalInfoError = false"
        >
          ok
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalsInfoError',
  props: ['giveModuleVar'],
  data() {
    return {
    };
  },
  computed: {
    showModalInfoError: {
      get() {
        return this.$store.getters.showModalInfoError;
      },
      set(data) {
        this.$store.commit('setShowModalInfoError', data);
      },
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-info-error {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);
    padding: 15px;

    &__content {
      max-width: 562px;
      width: 100%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 65px 15px;
      padding-bottom: 25px;
      box-shadow: 0 16px 24px rgba(48, 49, 51, .2);
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 40px;
      font-size: 31px;
      color: $color-shark;
      text-align: center;
      margin-bottom: 56px;
    }

    &__desc {
      font-size: 18px;
      line-height: 30px;
      font-family: $font-global;
      margin-bottom: 50px;
      color: $color-shark;
      max-width: 505px;
      padding-left: 14px;
      width: 100%;

      div + div {
        margin-top: 16px;
        font-style: italic;
      }
    }

    &__btn {
      margin: auto;
      width: 104px;
      height: 34px;
      text-transform: uppercase;
      transition: opacity .15s ease-in;

      &:hover {
        opacity: .6;
      }
    }
  }
</style>
